<template>
    <div class="page">
        <div class="html-container" v-html="info" />
        <div class="footer">
            <div class="closeBtn" @click="closePage">知道了</div>
        </div>
    </div>
</template>
<script>
import { APPJS } from '@/utils/native';
import http from '@/http';

export default {
    data(){
        return {
            info: null
        }
    },
    created() {
        http.post('/?cmd=com.xqxc.api.pigear.biz.platform.TransportCompanyService.getRechargeInfo', {
            id: this.$route.query.transportId
        }).then(res => {
            this.info = res.result.rechargeInfo;
        })
    },
    mounted() {
    },
    methods: {
        closePage(){
            APPJS('closeweb');
        },

    },
}
</script>
<style lang="less" scoped>
.page { 
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .html-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 1rem;
        left: 0;
        overflow: auto;
        font-size: .28rem;
        padding: .3rem;
        table{
            max-width: 100%;
            margin: .1rem auto;
        }
        /deep/ p { line-height:1.2; margin-top:.25rem; text-indent: .56rem}
        /deep/ p strong{ font-weight: bold;}
        /deep/ p b{ font-weight: bold;}
    }
    .footer{
        position: absolute;
        width: 100%;
        padding: 0 .3rem;
        box-sizing: border-box;
        height: 1rem;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .closeBtn{
        width: 100%;
        height: .88rem;
        border-radius: .1rem;
        background: #00ABEB;
        color: #FFF;
        font-size: .32rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
    
</style>
    